window.jQuery = window.$ = require('jquery');
var processInclude = require("suzano/util");

$(document).ready(function () {
    processInclude(require('../thirdParty/bootstrap'));
    processInclude(require('../hiddeMenu'));

    processInclude(require('../datalayer'));
});

import "slick-carousel";
import "lazysizes";
import "jquery-mask-plugin";
require('../components/spinner');