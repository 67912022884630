'use strict';

/**
 * Pushes an event to the dataLayer.
 * @param {Object} data - The event data to be pushed to the dataLayer.
 */
function pushEvent(data) {
    if (data) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push(data);
    }
}

$(document).ready(function () {
    $('body').on('dataLayerPush:event', (event, data) => {
        // console.log("🚀 ~ datalayer trigger call event: ", data)
        pushEvent(data);
    });
});
